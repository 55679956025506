import {
  headerActiveClasses,
  navigationActivatingClasses,
  navigationActiveClasses,
} from '../features/header/constants';
import {
  toggleClass,
  addClass,
  removeClass,
  removeAllActiveClasses,
} from '../features/header/util';
import {
  header,
  submenus,
  toggleSubmenuButtons,
  resetHeaderMenus,
} from '../features/header/index';
import {
  setQuickBasketCountFromCookie,
  initQuickBasket,
} from '../features/basket/mini-basket';
import { initialiseSwiper } from '../swiper/swiper';
import { initialiseLazySearch } from '../features/autocomplete/lazy';
import {
  getHeaderSearchInput,
  setHeaderSearchOpen,
} from '../features/autocomplete/header';
import {
  trackNavigationMenu,
  trackNoticeBar,
} from '../util/tracking';


document.addEventListener('DOMContentLoaded', () => {
  // TODO move most of this into ../features/header/index.js


  const mobileToggle = document.querySelector('[data-mobile-toggle]');
  const secondLevelButtons = document.querySelectorAll('[data-toggle-second-level]');
  const searchInput = getHeaderSearchInput();

  /**
   * Track all nav links
   */
  const initHeaderLinkTracking = () => {
    const nav = header.querySelector('[data-navigation]');

    nav.addEventListener('click', (e) => {
      if (e.target.tagName.toLowerCase() === 'a') {
        trackNavigationMenu('Navigation Link Clicked', e.target.textContent.trim(), e.target.href.trim());
      }
    });
  }

  /**
   * Track all notice bar links
   */
  const initNoticeBarLinkTracking = () => {
    const noticeBar = document.querySelector('aside.notice-bar');

    noticeBar.addEventListener('click', (e) => {
      if (e.target.tagName.toLowerCase() === 'a') {
        trackNoticeBar('Notice Bar Link Clicked', e.target.textContent.trim(), e.target.href.trim());
      }
    });
  }

  /**
   * Toggles the submenu based on the clicked button.
   * @param {Element} clickedButton
   */
  const toggleSubmenu = clickedButton => {
    const clickedSubmenu = clickedButton.nextElementSibling;
    const isActive = clickedSubmenu?.classList.contains(
      navigationActiveClasses.groups
    );

    resetHeaderMenus();
    setHeaderSearchOpen(false);

    // No fade on mobile
    const timeout = window.matchMedia('(max-width: 1024px)').matches ? 0 : 100;

    if (!isActive) {
      setTimeout(() => {
        addClass(clickedSubmenu, navigationActiveClasses.groups);
      }, timeout);
      addClass(clickedSubmenu, navigationActivatingClasses.groups);
      addClass(clickedButton, navigationActiveClasses.button);
      addClass(header, headerActiveClasses.submenu);
      clickedButton.setAttribute('aria-expanded', 'true');

      trackNavigationMenu('Open Submenu', clickedButton.textContent.trim());
    } else {
      setTimeout(() => {
        removeClass(clickedSubmenu, navigationActivatingClasses.groups);
        trackNavigationMenu('Close Submenu', clickedButton.textContent.trim());
      }, timeout);
    }
  };

  /**
   * Toggles the mobile menu.
   */
  const toggleMobileMenu = () => {
    const isMobileActive = header?.classList.toggle(headerActiveClasses.mobile);

    if (isMobileActive) {
      document.documentElement.classList.add('lock-scroll');
      closeSearch();
    } else {
      document.documentElement.classList.remove('lock-scroll');
      removeAllActiveClasses(header);
    }
  };

  /**
   * Close the search
   */
  const closeSearch = () => {
    const searchContainer = header.querySelector('.header-search');

    searchContainer.classList.remove('header-search--active');
  }

  /**
   * Toggles the second-level menu based on the clicked button.
   * @param {Element} clickedButton
   */
  const toggleSecondLevel = clickedButton => {
    toggleClass(clickedButton.parentElement, navigationActiveClasses.group);
    toggleClass(header, headerActiveClasses.secondLevel);

    if (header.classList.contains(headerActiveClasses.secondLevel)
      && clickedButton.parentElement.classList.contains(navigationActiveClasses.group))
    {
      trackNavigationMenu('Open Second Level Menu', clickedButton.textContent.trim());
    } else {
      trackNavigationMenu('Close Second Level Menu', clickedButton.textContent.trim());
    }

    clickedButton.setAttribute('aria-expanded', 'false');
  };

  /**
   * Initializes Intersection Observer to manage header position based on scrolling.
   */
  const initIntersectionObserver = () => {
    const sentinel = document.querySelector('[data-sentinel]');
    const observer = new IntersectionObserver(
      entries => {
        const position = entries[0].isIntersecting ? 'static' : 'sticky';
        header.setAttribute('data-position', position);
      },
      { threshold: 1.0 }
    );
    observer.observe(sentinel);
  };

  /**
   * Initializes a fallback scroll listener for browsers without IntersectionObserver support.
   */
  const initFallbackScrollListener = () => {
    window.addEventListener('scroll', () => {
      const position = window.scrollY > 0 ? 'static' : 'sticky';
      header.setAttribute('data-position', position);
    });
  };

  mobileToggle?.addEventListener('click', toggleMobileMenu);

  toggleSubmenuButtons.forEach(button => {
    button.addEventListener('click', e => toggleSubmenu(e.currentTarget));
  });

  secondLevelButtons.forEach(button => {
    button.addEventListener('click', e => toggleSecondLevel(e.currentTarget));
  });

  searchInput.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setHeaderSearchOpen(false);
    }
  });

  /**
   * Initialize Observers and Event Listeners
   */
  if (window.IntersectionObserver) {
    initIntersectionObserver();
  } else {
    initFallbackScrollListener();
  }

  /**
   * Close header when clicking outside of the header.
   */
  document.addEventListener('click', e => {
    if (header && !header.contains(e.target)) {
      if (header.classList.toString().includes('active')) {
        trackNavigationMenu('Closed Navigation Menu', 'Clicked Off Menu');
      }

      removeAllActiveClasses(header);
    }
  });

  const initNoticeCarousel = () => {
    const swiper = document.querySelector('[data-swiper]');

    initialiseSwiper(swiper);

    function manageMobileSlides(noticeSwiper, isMobile) {
      const mobileOnlySlides = document.querySelectorAll(
        '[data-mobile-only="true"]'
      );

      if (isMobile) {
        // Add mobile-only slides
        mobileOnlySlides.forEach(slide => {
          // Check if the slide is already appended
          if (
            !noticeSwiper.swiper.slides.some(
              swiperSlide => swiperSlide.outerHTML === slide.outerHTML
            )
          ) {
            noticeSwiper.swiper.appendSlide(slide.outerHTML);
          }
        });
      } else {
        let slidesToRemove = [];
        // Remove mobile-only slides
        noticeSwiper.swiper.slides.forEach((slide, index) => {
          if (slide.dataset.mobileOnly === 'true') {
            slidesToRemove.push(index);
          }
        });

        noticeSwiper.swiper.removeSlide(slidesToRemove);
      }

      noticeSwiper.swiper.update();
    }

    function managePrivateSale(noticeSwiper, isMobile) {
      const privateSaleSlides = noticeSwiper.querySelectorAll('[data-private-sale="true"]');

      if (privateSaleSlides.length) {
        const slidesToRemove = [];

        noticeSwiper.swiper.slides.forEach((slide, index) => {
          // On desktop we only want to show the private sale slides if there are any
          if (
            (isMobile && slide.dataset.privateSale !== 'true') ||
            (slide.dataset.mobileOnly !== 'true' && slide.dataset.privateSale !== 'true')
          ) {
            slidesToRemove.push(index);
          }
        });

        noticeSwiper.swiper.removeSlide(slidesToRemove);
      }

      noticeSwiper.swiper.update();
    }

    manageMobileSlides(swiper, window.innerWidth <= 1024);
    managePrivateSale(swiper, window.innerWidth <= 1024);

    swiper.style.opacity = 1;
  };

  initQuickBasket();
  initNoticeCarousel();
  initialiseLazySearch();
  initHeaderLinkTracking();
  initNoticeBarLinkTracking();
});

setQuickBasketCountFromCookie();
